import React, { useEffect, useState } from "react"
import {
  Nav,
  Logo,
  NavMenu,
  LogoImage,
  BurgerMenu,
  Drawer,
  NavContainer,
  NavLink,
  DrawerNavLink,
} from "./navbarElements"
import { MenuItems } from "./MenuItems"

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset
      if (scrollTop > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // Prevent scrolling when drawer is open
  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [isDrawerOpen])

  return (
    <header>
      <Nav
        isScrolled={isScrolled}
        role="navigation"
        aria-label="Main Navigation"
      >
        <NavContainer>
          <Logo href="/" aria-label="Home">
            <LogoImage
              src="/logo.png"
              alt="Website Logo"
              loading="lazy"
              isScrolled={isScrolled}
            />
          </Logo>

          <NavMenu isScrolled={isScrolled}>
            {MenuItems.map((item, index) => (
              <li key={index}>
                <NavLink href={item.url} aria-label={item.title}>
                  {item.title}
                </NavLink>
              </li>
            ))}
          </NavMenu>

          <BurgerMenu
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            isOpen={isDrawerOpen}
            aria-expanded={isDrawerOpen}
            aria-label={isDrawerOpen ? "Close menu" : "Open menu"}
          >
            <span />
            <span />
            <span />
          </BurgerMenu>
        </NavContainer>
      </Nav>

      <Drawer isOpen={isDrawerOpen} aria-hidden={!isDrawerOpen}>
        <nav>
          <h2 className="visually-hidden">Nawigacja telefonowa</h2>
          {MenuItems.map((item, index) => (
            <DrawerNavLink
              href={item.url}
              key={index}
              onClick={() => setIsDrawerOpen(false)}
              aria-label={item.title}
            >
              {item.title}
            </DrawerNavLink>
          ))}
        </nav>
      </Drawer>
    </header>
  )
}

export default Navbar
