import React, { useState, useEffect } from "react"
import {
  Overlay,
  Popup,
  ImageContainer,
  ContentContainer,
  CloseButton,
  Title,
  Subtitle,
  Paragraph,
  PriceTag,
  SubmitButton,
  NoDiscountLink,
} from "./popupElements"

const PromoPopupWithImage = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  const closePopup = () => {
    setIsVisible(false)
    localStorage.setItem("promoPopupClosed", "true")
  }

  const handleButtonClick = (event, shouldNavigate) => {
    if (shouldNavigate) {
      const section = document.querySelector("#contact")
      if (section) section.scrollIntoView({ behavior: "smooth" })
    }
    closePopup()
  }

  useEffect(() => {
    const isClosed = localStorage.getItem("promoPopupClosed") === "true"
    if (isClosed) {
      setIsVisible(false)
    }
  }, [])

  if (!isVisible) return null

  return (
    <Overlay role="dialog" aria-modal="true" aria-labelledby="popup-title">
      <Popup>
        <ImageContainer>
          <span className="sr-only">Dom w promocji</span>
        </ImageContainer>
        <ContentContainer>
          <CloseButton onClick={closePopup} aria-label="Zamknij promocję">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CloseButton>
          <Title id="popup-title">RUSZYŁA PROMOCJA</Title>
          <Subtitle>Limitowana oferta</Subtitle>
          <Paragraph>DO ODWOŁANIA DOM W CENIE</Paragraph>
          <PriceTag>699 000 ZŁ</PriceTag>
          <SubmitButton
            href="#contact"
            onClick={(event) => handleButtonClick(event, true)}
          >
            Skorzystaj z promocji
          </SubmitButton>
          <NoDiscountLink
            href="#"
            onClick={(event) => handleButtonClick(event, false)}
          >
            Nie, dziękuję
          </NoDiscountLink>
        </ContentContainer>
      </Popup>
    </Overlay>
  )
}

export default PromoPopupWithImage
