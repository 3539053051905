import React from "react"
import {
  Header,
  TextBox,
  HeroContent,
  About,
  Container,
  Photos,
  AboutContent,
  LookFor,
  Our,
  Row,
  OurCol,
  Ruo,
  ContactSection,
  ContactContent,
  HeaderInfo,
  Description,
  StatusContainer,
  ImagesContainer,
  ContactButton,
  WhatsAppButton,
  Highlight,
} from "./homeElements"
import { FaWhatsapp } from "react-icons/fa"

import styled, { keyframes } from "styled-components"
import img7 from "../../assets/img7.jpg"
import img1 from "../../assets/img1.jpg"
import img4 from "../../assets/img4.jpg"
import img6 from "../../assets/img6.jpg"
import jan from "../../assets/jan-img.jpg"

import Layout from "../Layout"
import PopUp from "../PopUp"

import budowa1 from "../../assets/budowa1.jpeg"
import budowa2 from "../../assets/budowa2.jpeg"
import budowa3 from "../../assets/budowa3.jpeg"
import budowa4 from "../../assets/budowa4.jpeg"
import budowa5 from "../../assets/budowa5.jpeg"
import budowa6 from "../../assets/budowa6.jpeg"
import budowa7 from "../../assets/budowa7.jpeg"
import budowa8 from "../../assets/budowa8.jpeg"

const ParallaxContainer = styled.div`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 1px;
`

const Background = styled.div`
  background-image: url(${img7});
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`

const ParallaxEffect = () => (
  <ParallaxContainer>
    <Background
      role="img"
      aria-label="Panoramiczny widok osiedla Wzgórze Milenijne"
    />
  </ParallaxContainer>
)

const slideUpEnter = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100px);
    }
`

const SlideEnter = styled.div`
  &.slide-enter {
    animation: ${slideUpEnter} 2s ease-in-out forwards;
  }
`

const Home = () => {
  const images = [
    budowa1,
    budowa2,
    budowa3,
    budowa4,
    budowa5,
    budowa6,
    budowa7,
    budowa8,
  ]
  return (
    <>
      <PopUp></PopUp>
      <Header>
        <TextBox>
          <HeroContent>
            <SlideEnter className="slide-enter">
              <h1>WZGÓRZE MILENIJNE</h1>
              <p>
                Witamy na Wzgórzu Milenijnym – miejscu, gdzie nowoczesność
                spotyka się z naturą. Nasze nowe osiedle domków oferuje
                przyszłym mieszkańcom nie tylko komfortowe i nowoczesne
                przestrzenie mieszkalne, ale również bliskość natury i spokój z
                dala od miejskiego zgiełku. Wzgórze Milenijne to inwestycja,
                która jest dopiero w trakcie realizacji, co daje przyszłym
                właścicielom możliwość personalizacji swojego wymarzonego domu.
              </p>
            </SlideEnter>
          </HeroContent>
        </TextBox>
      </Header>

      <main>
        {/* About Section */}
        <article id="about">
          <About>
            <Container>
              <Photos>
                <div
                  role="img"
                  aria-label="Zdjęcie osiedla Wzgórze Milenijne"
                />
              </Photos>
              <AboutContent>
                <h2>WZGÓRZE MILENIJNE</h2>
                <p>
                  Wzgórze Milenijne stanowi miejsce, gdzie nowoczesność
                  spleciona jest z pięknem przyrody, oferując przyszłym
                  mieszkańcom komfortowe, nowoczesne przestrzenie mieszkalne w
                  otoczeniu zieleni oraz spokoju, oddalone od miejskiego
                  zgiełku. To unikatowa inwestycja, obecnie w trakcie
                  realizacji, umożliwiająca przyszłym właścicielom
                  personalizację ich wymarzonego domu. Każdy dom na Wzgórzu
                  Milenijnym został starannie zaprojektowany z myślą o komforcie
                  i funkcjonalności, łącząc w sobie nowoczesne rozwiązania
                  technologiczne z eleganckim, ponadczasowym designem. Projekt
                  odznacza się nie tylko wysokim standardem, ale także
                  przemyślaną architekturą oraz dbałością o detale, które tworzą
                  niepowtarzalny charakter tego miejsca. Osiedle Wzgórze
                  Milenijne może pochwalić się dobrze rozwiniętą infrastrukturą,
                  co gwarantuje łatwy dostęp do lokalnych atrakcji, sklepów,
                  placówek edukacyjnych oraz terenów rekreacyjnych i zielonych.
                  Jest to inwestycja, która zaspokoi oczekiwania nawet
                  najbardziej wymagających klientów, pragnących połączyć komfort
                  życia w mieście z zaletami bliskiego kontaktu z naturą.
                </p>
                <LookFor>
                  <a href="#contact">Skontaktuj się z nami</a>
                </LookFor>
              </AboutContent>
            </Container>
          </About>
        </article>

        {/* Parallax Effect */}
        <ParallaxEffect></ParallaxEffect>

        {/* Features Section */}
        <article id="our">
          <Our>
            <h2>UNIKATOWOŚĆ NASZEJ INWESTYCJI</h2>
            <p>
              Nasza inwestycja stanowi wyjątkową perłę na rynku nieruchomości,
              oferując unikatowe połączenie nowoczesnego designu,
              zaawansowanych, ekologicznych technologii oraz ekskluzywnej
              lokalizacji w bliskim kontakcie z naturą, co razem tworzy
              propozycję bez precedensu dla najbardziej wymagających klientów.
            </p>
            <Row>
              <OurCol>
                <img
                  src={img1}
                  alt="Przykład innowacyjnej architektury"
                  loading="lazy"
                />
                <h3>Innowacyjna architektura</h3>
              </OurCol>
              <OurCol>
                <img
                  src={img6}
                  alt="Ekologiczne rozwiązania w naszych domach"
                  loading="lazy"
                />
                <h3>Ekologiczny standard</h3>
              </OurCol>
              <OurCol>
                <img
                  src={img4}
                  alt="Otoczenie przyrody wokół osiedla"
                  loading="lazy"
                />
                <h3>Zacisze przyrody</h3>
              </OurCol>
            </Row>
          </Our>
        </article>

        {/* Information Section */}
        <article id="information">
          <Ruo>
            <h2>INFORMACJE</h2>

            {/* First Information Block */}
            <section className="service">
              <div className="service-image">
                <img
                  src={img1}
                  alt="Widok na lokalizację Wzgórza Milenijnego"
                  loading="lazy"
                />
              </div>
              <div className="service-content">
                <p className="section-number">01.</p>
                <div>
                  <h3>Idealna Lokalizacja</h3>
                  <p>
                    Lokalizacja Wzgórza Milenijnego to nie tylko kluczowy atut
                    tej inwestycji, ale również gwarancja wyjątkowego komfortu
                    życia. Dla osób ceniących spokój i jednocześnie pragnących
                    łatwego dostępu do miejskiej infrastruktury, osiedle to
                    stanowi idealną przestrzeń do zamieszkania. Zlokalizowane w
                    strategicznym miejscu, zapewnia świetne połączenia z centrum
                    miasta oraz głównymi arteriami. Dookoła rozciąga się zieleń
                    i tereny rekreacyjne, co czyni to miejsce idealnym dla
                    rodzin z dziećmi oraz osób aktywnych, poszukujących
                    równowagi między naturą a życiem miejskim. W pobliżu
                    znajdują się sklepy, szkoły oraz usługi, co dodatkowo
                    zwiększa wygodę codziennego życia bez konieczności dalekich
                    dojazdów.
                  </p>
                </div>
              </div>
            </section>

            {/* Second Information Block */}
            <section className="service">
              <div className="service-image">
                <img
                  src={img4}
                  alt="Nowoczesna architektura domów w otoczeniu natury"
                  loading="lazy"
                />
              </div>
              <div className="service-content">
                <p className="section-number">02.</p>
                <div>
                  <h3>Nowoczesna Architektura w Harmonii z Naturą</h3>
                  <p>
                    "Skrzynka" to inwestycja, która redefiniuje nowoczesne
                    budownictwo w Małopolsce. Osiedle składające się z domów w
                    zabudowie bliźniaczej wyróżnia się nowoczesnym,
                    minimalistycznym designem. Segmenty, o powierzchni 120 m²
                    każdy, zostały zaprojektowane tak, by harmonijnie łączyć
                    nowoczesną estetykę z otaczającą przyrodą. Wykorzystanie
                    różnorodnych materiałów dodaje całości indywidualnego
                    charakteru, tworząc przestrzeń, która jest nie tylko
                    funkcjonalna, ale także atrakcyjna wizualnie.
                  </p>
                </div>
              </div>
            </section>
          </Ruo>
        </article>

        {/* Current Status Section */}
        <article id="status">
          <StatusContainer>
            <h2>AKTUALNY STATUS PRAC</h2>
            <Description>
              Z radością informujemy, że realizujemy inwestycję{" "}
              <strong>Milenijne Wzgórze</strong> – nowoczesny kompleks domów w
              zabudowie bliźniaczej, usytuowany na szczycie wzgórza w
              Dobczycach. Inwestycja oferuje piękne widoki na okolicę, doskonały
              dostęp do pełnej infrastruktury oraz wyjątkową jakość wykonania.
              <br />
              <br />
              Inwestycja łączy funkcjonalność, nowoczesny i ponadczasowy design
              oraz wysoki standard użytych materiałów. W pierwszym etapie budowy
              powstają{" "}
              <strong>
                4 domy w zabudowie bliźniaczej o powierzchniach 96 m²
              </strong>
              .
            </Description>
            <Highlight></Highlight>

            <ImagesContainer>
              <img
                src={budowa1}
                alt="Postęp budowy - etap pierwszy"
                loading="lazy"
              />
              <img
                src={budowa5}
                alt="Postęp budowy - etap pośredni"
                loading="lazy"
              />
              <img
                src={budowa7}
                alt="Postęp budowy - aktualny stan"
                loading="lazy"
              />
            </ImagesContainer>

            <ContactButton>Kontakt</ContactButton>
            <WhatsAppButton href="tel:+48600881188" aria-label="Zadzwoń do nas">
              <FaWhatsapp aria-hidden="true" />
            </WhatsAppButton>
          </StatusContainer>
        </article>

        <Layout />

        {/* Contact Section */}
        <article id="contact">
          <ContactSection>
            <ContactContent>
              <div className="contact-text">
                <h2>
                  Sprzedaż inwestycji prowadzi Grupa Nieruchomości Kraków sp. z
                  o.o.
                </h2>
                <p>
                  Biuro specjalizujące się w obsłudze sprzedaży inwestycji
                  deweloperskich.
                </p>
                <p>Jan Palka</p>
                <p>
                  <a href="tel:+48666111998">+48 666 111 998</a>
                </p>
                <p>
                  <a href="mailto:jan.palka@gnk.pl">jan.palka@gnk.pl</a>
                </p>
              </div>
              <div className="contact-image">
                <img
                  src={jan}
                  alt="Jan Palka - konsultant sprzedaży"
                  loading="lazy"
                />
              </div>
            </ContactContent>
          </ContactSection>
        </article>
      </main>
    </>
  )
}

export default Home
