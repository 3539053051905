import styled from "styled-components"
import {
  FaUser,
  FaEnvelope,
  FaMapMarkerAlt,
  FaPhone,
  FaCheckCircle,
} from "react-icons/fa"

// Section styles
export const ContactUsSection = styled.section`
  padding: 80px 0;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 60px 0;
  }
`

export const ContactContainer = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`

export const SectionHeading = styled.div`
  text-align: center;
  margin-bottom: 60px;

  span {
    color: #2f4f4f;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
    color: #333;
    margin: 12px 0;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
`

export const Underline = styled.div`
  height: 4px;
  width: 70px;
  background-color: #2f4f4f;
  margin: 0 auto;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`

export const ContactInfoCol = styled.div`
  flex: 1;
  min-width: 300px;
`

export const ContactFormCol = styled.div`
  flex: 1;
  min-width: 300px;
`

// Contact info card styles
export const ContactInfoCard = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`

export const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0 0 10px 0;
  }

  p {
    margin: 5px 0;
    color: #555;
    line-height: 1.5;
  }

  small {
    font-size: 12px;
    color: #777;
    margin-top: 5px;
  }
`

export const ContactLink = styled.a`
  color: #2f4f4f;
  text-decoration: none;
  margin: 5px 0;
  transition: color 0.3s ease;
  display: block;

  &:hover {
    color: #507f7f;
    text-decoration: underline;
  }
`

// Icons
const iconStyle = `
  min-width: 40px;
  height: 40px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2f4f4f;
  background-color: #f0f7f7;
  border-radius: 50%;
  padding: 8px;
  
  @media (max-width: 768px) {
    min-width: 35px;
    height: 35px;
  }
`

export const UserIcon = styled(FaUser)`
  ${iconStyle}
`

export const EnvelopeIcon = styled(FaEnvelope)`
  ${iconStyle}
`

export const MapIcon = styled(FaMapMarkerAlt)`
  ${iconStyle}
`

export const PhoneIcon = styled(FaPhone)`
  ${iconStyle}
`

// Location map styles
export const LocationSection = styled.div`
  width: 100%;
  margin-top: 60px;

  iframe {
    width: 100%;
    height: 450px;

    @media (max-width: 768px) {
      height: 300px;
    }
  }
`
