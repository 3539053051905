import React from "react"
import {
  FooterContainer,
  FooterContent,
  FooterSection,
  FooterLogo,
  FooterLinks,
  FooterSocial,
  SocialIcon,
  CopyRight,
  FooterLink,
  FooterHeading,
} from "./footerElements"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer aria-label="Site footer">
      <FooterContainer>
        <FooterContent>
          <FooterSection>
            <FooterLogo>
              <img
                src="/logo.png"
                alt="Ask Development Logo"
                loading="lazy"
                width="120"
                height="50"
              />
              <p>
                W malowniczych okolicach Dobczyc, tuż przy Krakowie, powstały
                domy rodzinne z przestronnymi wnętrzami, zielonymi ogrodami i
                udogodnieniami dla aktywnych. Lokalizacja gwarantuje spokój oraz
                szybki dostęp do atrakcji miejskich, czyniąc tę inwestycję
                idealnym wyborem dla rodzin.{" "}
              </p>
            </FooterLogo>
          </FooterSection>

          <FooterSection>
            <FooterHeading>Szybkie linki</FooterHeading>
            <FooterLinks>
              <li>
                <FooterLink href="/">Strona główna</FooterLink>
              </li>
              <li>
                <FooterLink href="/#about">Inwesytcja</FooterLink>
              </li>
              <li>
                <FooterLink href="/#status">Status prac</FooterLink>
              </li>
              <li>
                <FooterLink href="/#contact">Kontakt</FooterLink>
              </li>
              <li>
                <FooterLink href="/#layout">Lokale</FooterLink>
              </li>
            </FooterLinks>
          </FooterSection>

          <FooterSection>
            <FooterHeading>Skontaktuj się z nami!</FooterHeading>
            <FooterLinks>
              <li>
                <FooterLink href="mailto:biuro@askdevelopment.pl">
                  biuro@askdevelopment.pl
                </FooterLink>
              </li>
              <li>
                <FooterLink href="tel:+48606647277">+48 606-647-277</FooterLink>
              </li>
              <li>
                <FooterLink href="tel:+48600881188">+48 600-881-188</FooterLink>
              </li>
              <li>
                <address>
                  Cystersów 13A/1
                  <br />
                  31-553 Kraków, Polska
                </address>
              </li>
            </FooterLinks>
          </FooterSection>

          {/* <FooterSection>
            <FooterHeading>Connect</FooterHeading>
            <FooterSocial>
              <SocialIcon href="https://linkedin.com" aria-label="LinkedIn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z" />
                </svg>
              </SocialIcon>
              <SocialIcon href="https://twitter.com" aria-label="Twitter">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z" />
                </svg>
              </SocialIcon>
              <SocialIcon href="https://facebook.com" aria-label="Facebook">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M12 2.04c-5.5 0-10 4.49-10 10.02 0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89 1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02z" />
                </svg>
              </SocialIcon>
            </FooterSocial>
          </FooterSection> */}
        </FooterContent>
      </FooterContainer>
      <CopyRight>
        <p>
          Copyright © {currentYear} <strong>Onit Soft</strong>. All rights
          reserved.
        </p>
        {/* <nav aria-label="Footer menu">
            <FooterLink href="/privacy">Privacy Policy</FooterLink>
            <FooterLink href="/terms">Terms of Service</FooterLink>
          </nav> */}
      </CopyRight>
    </footer>
  )
}

export default Footer
