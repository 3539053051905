import styled from "styled-components"
import { FaCheckCircle } from "react-icons/fa"

// Form wrapper
export const FormWrapper = styled.form`
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);

  h3 {
    font-size: 22px;
    font-weight: 600;
    color: #2f4f4f;
    margin-bottom: 25px;
    position: relative;
    padding-bottom: 12px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 3px;
      background-color: #2f4f4f;
    }
  }

  @media (max-width: 768px) {
    padding: 20px;

    h3 {
      font-size: 20px;
    }
  }
`

// Form group
export const FormGroup = styled.div`
  margin-bottom: 20px;
`

// Label
export const FormLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #555;
`

// Input wrapper (for icon + input)
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

// Input icon container
export const InputIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #2f4f4f;
  opacity: 0.7;
  z-index: 1;
`

// Input field
export const StyledInput = styled.input`
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid ${(props) => (props.hasError ? "#e74c3c" : "#e0e0e0")};
  border-radius: 6px;
  font-size: 15px;
  transition: all 0.3s ease;
  background-color: ${(props) => (props.hasError ? "#fff8f8" : "#fff")};

  &:focus {
    border-color: #2f4f4f;
    box-shadow: 0 0 0 2px rgba(47, 79, 79, 0.1);
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 10px 10px 38px;
  }
`

// Textarea
export const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid ${(props) => (props.hasError ? "#e74c3c" : "#e0e0e0")};
  border-radius: 6px;
  font-size: 15px;
  transition: all 0.3s ease;
  resize: vertical;
  min-height: 120px;
  background-color: ${(props) => (props.hasError ? "#fff8f8" : "#fff")};

  &:focus {
    border-color: #2f4f4f;
    box-shadow: 0 0 0 2px rgba(47, 79, 79, 0.1);
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
    min-height: 100px;
  }
`

// Error message
export const ErrorMessage = styled.div`
  color: #e74c3c;
  font-size: 13px;
  margin-top: 5px;
  font-weight: 500;
`

// Submit button
export const SubmitButton = styled.button`
  display: block;
  width: 100%;
  padding: 14px;
  background-color: #2f4f4f;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  text-align: center;

  &:hover {
    background-color: #3a6565;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background-color: #95b1b1;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 15px;
  }
`

// Success message
export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
  background-color: #edf7ed;
  border: 1px solid #c3e6cb;
  border-radius: 6px;
  animation: fadeIn 0.5s ease-in;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  strong {
    display: block;
    font-size: 16px;
    color: #155724;
    margin-bottom: 4px;
  }

  p {
    margin: 0;
    color: #2d6a4f;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    padding: 12px;

    strong {
      font-size: 15px;
    }

    p {
      font-size: 13px;
    }
  }
`

// Success icon
export const SuccessIcon = styled.div`
  margin-right: 15px;
  color: #28a745;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CheckIcon = styled(FaCheckCircle)`
  font-size: 24px;
`
