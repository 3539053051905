import styled from "styled-components"

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-shadow: ${(props) =>
    !props.isScrolled ? "none" : "0 5px 15px 0 rgba(0, 0, 0, .08)"};
  background-color: ${(props) =>
    !props.isScrolled ? "rgba(255, 255, 255, .9)" : "rgba(255, 255, 255, .95)"};
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  height: ${(props) => (!props.isScrolled ? "120px" : "90px")};
`

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  margin: 0 auto;
`

export const Logo = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  z-index: 1000;

  &:focus {
    outline: 2px solid #2f4f4f;
    outline-offset: 4px;
  }
`

export const LogoImage = styled.img`
  height: auto;
  max-height: ${(props) => (!props.isScrolled ? "110px" : "80px")};
  width: auto;
  transition: all 0.3s ease;
`

export const NavMenu = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const NavLink = styled.a`
  display: block;
  padding: 10px 16px;
  margin: 0 4px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #2f4f4f;
  position: relative;
  transition: all 0.2s ease;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #2f4f4f;
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  &:hover,
  &:focus {
    color: #000;

    &:after {
      width: 80%;
    }
  }

  &:focus {
    outline: 2px solid #2f4f4f;
    outline-offset: 4px;
  }
`

export const BurgerMenu = styled.button`
  display: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1000;
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 4px;

  & > span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #2f4f4f;
    transition: all 0.3s ease;

    &:first-of-type {
      transform: ${(props) =>
        props.isOpen ? "translateY(9px) rotate(45deg)" : "rotate(0deg)"};
    }

    &:nth-child(2) {
      margin: 6px 0;
      opacity: ${(props) => (props.isOpen ? 0 : 1)};
    }

    &:last-of-type {
      transform: ${(props) =>
        props.isOpen ? "translateY(-9px) rotate(-45deg)" : "rotate(0deg)"};
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &:focus {
    outline: 2px solid #2f4f4f;
    outline-offset: 2px;
  }
`

export const Drawer = styled.aside`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: ${(props) =>
    props.isOpen ? "translateY(0)" : "translateY(-100%)"};
  transition: transform 0.4s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(20px);
  z-index: 999;
  padding: 24px;

  & nav {
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
`

export const DrawerNavLink = styled.a`
  display: block;
  color: #2f4f4f;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  margin: 24px 0;
  padding: 12px;
  transition: all 0.3s ease;
  border-radius: 8px;

  &:hover,
  &:focus {
    background-color: rgba(47, 79, 79, 0.1);
    transform: translateY(-2px);
  }

  &:focus {
    outline: 2px solid #2f4f4f;
    outline-offset: 4px;
  }
`

// Add this class for screen reader only text
export const GlobalStyle = styled.div`
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`
