import React, { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"

import {
  ContactUsSection,
  ContactContainer,
  SectionHeading,
  Underline,
  Row,
  ContactInfoCol,
  ContactFormCol,
  ContactInfoCard,
  ContactDetails,
  ContactLink,
  UserIcon,
  EnvelopeIcon,
  MapIcon,
  PhoneIcon,
  LocationSection,
} from "./contactElements"

import {
  FormWrapper,
  FormGroup,
  FormLabel,
  InputWrapper,
  InputIcon,
  StyledInput,
  StyledTextarea,
  ErrorMessage,
  SubmitButton,
  SuccessMessage,
  SuccessIcon,
} from "./formElements"
import API from "../../api"

import { FaUser, FaEnvelope, FaPhone, FaCheckCircle } from "react-icons/fa"

// SEO-optimized component with proper semantic HTML
const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
  })

  const onSubmit = async (data) => {
    data.category = "contact request"

    try {
      await API.sendContactRequest(data)
      setIsSubmitted(true)
      setShowSuccessMessage(true)
      reset()

      // Scroll to success message
      const successElement = document.getElementById("success-message")
      if (successElement) {
        successElement.scrollIntoView({ behavior: "smooth", block: "center" })
      }

      // Hide success message after 5 seconds
      setTimeout(() => setShowSuccessMessage(false), 5000)
    } catch (error) {
      console.error("Error sending contact request:", error)
    }
  }

  return (
    <FormWrapper
      onSubmit={handleSubmit(onSubmit)}
      aria-labelledby="contact-form-heading"
    >
      <h3 id="contact-form-heading">Wyślij wiadomość</h3>

      <FormGroup>
        <FormLabel htmlFor="name">Imię i nazwisko</FormLabel>
        <InputWrapper>
          <InputIcon>
            <FaUser />
          </InputIcon>
          <Controller
            control={control}
            name="name"
            rules={{ required: "To pole jest wymagane" }}
            render={({ field }) => (
              <StyledInput
                type="text"
                id="name"
                placeholder="Twoje imię i nazwisko"
                aria-required="true"
                hasError={!!errors.name}
                {...field}
                {...register("name", { required: true })}
              />
            )}
          />
        </InputWrapper>
        {errors.name && <ErrorMessage>Podaj imię i nazwisko</ErrorMessage>}
      </FormGroup>

      <FormGroup>
        <FormLabel htmlFor="email">Email</FormLabel>
        <InputWrapper>
          <InputIcon>
            <FaEnvelope />
          </InputIcon>
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email jest wymagany",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Niepoprawny adres email",
              },
            }}
            render={({ field }) => (
              <StyledInput
                type="email"
                id="email"
                placeholder="Twój adres e-mail"
                aria-required="true"
                hasError={!!errors.email}
                {...field}
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
            )}
          />
        </InputWrapper>
        {errors.email && (
          <ErrorMessage>
            {errors.email.message || "Podaj poprawny adres email"}
          </ErrorMessage>
        )}
      </FormGroup>

      <FormGroup>
        <FormLabel htmlFor="phone">Telefon</FormLabel>
        <InputWrapper>
          <InputIcon>
            <FaPhone />
          </InputIcon>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: "Numer telefonu jest wymagany",
              pattern: {
                value: /^[0-9+\- ]{9,15}$/,
                message: "Niepoprawny numer telefonu",
              },
            }}
            render={({ field }) => (
              <StyledInput
                type="tel"
                id="phone"
                placeholder="Twój numer telefonu"
                aria-required="true"
                hasError={!!errors.phone}
                {...field}
                {...register("phone", {
                  required: true,
                  pattern: /^[0-9+\- ]{9,15}$/,
                })}
              />
            )}
          />
        </InputWrapper>
        {errors.phone && (
          <ErrorMessage>
            {errors.phone.message || "Podaj poprawny numer telefonu"}
          </ErrorMessage>
        )}
      </FormGroup>

      <FormGroup>
        <FormLabel htmlFor="subject">Temat</FormLabel>
        <InputWrapper>
          <Controller
            control={control}
            name="subject"
            rules={{ required: "Temat jest wymagany" }}
            render={({ field }) => (
              <StyledInput
                type="text"
                id="subject"
                placeholder="Temat Twojej wiadomości"
                aria-required="true"
                hasError={!!errors.subject}
                {...field}
                {...register("subject", { required: true })}
              />
            )}
          />
        </InputWrapper>
        {errors.subject && <ErrorMessage>Podaj temat wiadomości</ErrorMessage>}
      </FormGroup>

      <FormGroup>
        <FormLabel htmlFor="message">Wiadomość</FormLabel>
        <Controller
          control={control}
          name="message"
          rules={{ required: "Wiadomość jest wymagana" }}
          render={({ field }) => (
            <StyledTextarea
              rows="8"
              id="message"
              placeholder="Treść Twojej wiadomości"
              aria-required="true"
              hasError={!!errors.message}
              {...field}
              {...register("message", { required: true })}
            />
          )}
        />
        {errors.message && <ErrorMessage>Wpisz treść wiadomości</ErrorMessage>}
      </FormGroup>

      <SubmitButton type="submit" disabled={isSubmitted && showSuccessMessage}>
        Wyślij wiadomość
      </SubmitButton>

      {showSuccessMessage && (
        <SuccessMessage id="success-message" role="alert">
          <SuccessIcon>
            <FaCheckCircle />
          </SuccessIcon>
          <div>
            <strong>Dziękujemy!</strong>
            <p>
              Twoja wiadomość została wysłana pomyślnie. Odpowiemy najszybciej
              jak to możliwe.
            </p>
          </div>
        </SuccessMessage>
      )}
    </FormWrapper>
  )
}

const Contact = () => {
  // Added useEffect for better SEO and accessibility
  useEffect(() => {
    // Set page metadata for SEO
    document.title = "Kontakt - ASK Development | Domy na sprzedaż w Krakowie"

    // Add meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Skontaktuj się z ASK Development - deweloperem domów i mieszkań w Krakowie. Zadzwoń +48 606-647-277 lub wypełnij formularz kontaktowy. Biuro: Cystersów 13A/1, Kraków."
      )
    }
  }, [])

  return (
    <section itemScope itemType="https://schema.org/RealEstateAgent">
      <ContactUsSection id="contact">
        <ContactContainer>
          <SectionHeading>
            <span>Kontakt</span>
            <h2 itemProp="name">SKONTAKTUJ SIĘ Z NAMI</h2>
            <Underline />
          </SectionHeading>

          <Row>
            <ContactInfoCol>
              <ContactInfoCard itemScope itemType="https://schema.org/Person">
                <UserIcon aria-hidden="true" />
                <ContactDetails>
                  <h5 itemProp="name">Robert Matracki</h5>
                  <ContactLink
                    href="mailto:robert.matracki@askdevelopment.pl"
                    itemProp="email"
                  >
                    robert.matracki@askdevelopment.pl
                  </ContactLink>
                  <ContactLink href="tel:+48606647277" itemProp="telephone">
                    +48 606-647-277
                  </ContactLink>
                </ContactDetails>
              </ContactInfoCard>

              <ContactInfoCard itemScope itemType="https://schema.org/Person">
                <UserIcon aria-hidden="true" />
                <ContactDetails>
                  <h5 itemProp="name">Zbigniew Kowalski</h5>
                  <ContactLink
                    href="mailto:zbigniew.kowalski@askdevelopment.pl"
                    itemProp="email"
                  >
                    zbigniew.kowalski@askdevelopment.pl
                  </ContactLink>
                  <ContactLink href="tel:+48600881188" itemProp="telephone">
                    +48 600-881-188
                  </ContactLink>
                </ContactDetails>
              </ContactInfoCard>

              <ContactInfoCard>
                <EnvelopeIcon aria-hidden="true" />
                <ContactDetails
                  itemProp="address"
                  itemScope
                  itemType="https://schema.org/PostalAddress"
                >
                  <h5>Adres biura</h5>
                  <p>
                    <span itemProp="streetAddress">Cystersów 13A/1</span>
                    <br />
                    <span itemProp="postalCode">31-553</span>{" "}
                    <span itemProp="addressLocality">Kraków</span>,{" "}
                    <span itemProp="addressCountry">Polska</span>
                  </p>
                </ContactDetails>
              </ContactInfoCard>

              <ContactInfoCard>
                <MapIcon aria-hidden="true" />
                <ContactDetails>
                  <h5>Email biura</h5>
                  <ContactLink
                    href="mailto:biuro@askdevelopment.pl"
                    itemProp="email"
                  >
                    biuro@askdevelopment.pl
                  </ContactLink>
                  <small>Wyślij do nas wiadomość e-mail</small>
                </ContactDetails>
              </ContactInfoCard>
            </ContactInfoCol>

            <ContactFormCol>
              <ContactForm />
            </ContactFormCol>
          </Row>
        </ContactContainer>
      </ContactUsSection>

      <LocationSection>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10283.879202010969!2d20.1141754!3d49.8805937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716152c817bea5d%3A0x7afa20a36039a3aa!2sKrzy%C5%BC%20milenijny%20na%20Skrzynce!5e0!3m2!1spl!2spl!4v1697529401789!5m2!1spl!2spl"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Lokalizacja ASK Development - Kraków"
          aria-label="Mapa z lokalizacją biura ASK Development"
        ></iframe>
      </LocationSection>
    </section>
  )
}
export default Contact
