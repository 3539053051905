import styled from "styled-components"

export const FooterContainer = styled.div`
  background: linear-gradient(to right, #2c3e50, #2f4f4f);
  padding: 0;
  width: 100%;
  color: #fff;
`

export const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 24px 40px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 30px;
    padding: 40px 20px 30px;
  }
`

export const FooterSection = styled.section`
  display: flex;
  flex-direction: column;
`

export const FooterHeading = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  color: #f6c000;

  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

export const FooterLogo = styled.div`
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 16px;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 16px;
  }
`

export const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 12px;
  }

  address {
    font-style: normal;
    font-size: 0.9rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.8);
  }
`

export const FooterLink = styled.a`
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  position: relative;

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;

    &:after {
      width: 100%;
      opacity: 1;
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #fff;
    transition: all 0.3s ease;
    opacity: 0;
  }

  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.5);
    outline-offset: 2px;
  }
`

export const FooterSocial = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 4px;
`

export const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-3px);
  }

  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.5);
    outline-offset: 2px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`

export const CopyRight = styled.div`
  padding: 24px;
  background: linear-gradient(to right, #2c3e50, #2f4f4f);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  align-items: center;
  gap: 12px;
  text-align: center;

  p {
    color: white;
    font-size: 0.9rem;
    margin: 0;
  }

  strong {
    color: #f6c000;
  }

  nav {
    display: flex;
    gap: 20px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 60px;

    nav {
      gap: 24px;
    }
  }
`
